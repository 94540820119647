<template>

<div id="container" class="function-container">
    <h2 class="blind">본문 내용</h2>

    <div class="section section1">
        <div class="inner">
            <h3>마켓봄 프로의 <strong class="bold">상세 기능</strong></h3>
            <p>마켓봄 프로가 가진 다양한 기능을 확인해 보세요.</p>
        </div>
    </div>

    <div class="section section2">
        <div class="inner tab-wrap cb">
            <div class="tab js-tab">
                <!-- 탭 스크립트 이벤트 : js-tab -->
                <a v-for="(detail, i) in details" :key="i" @click="selectDetail(detail)"
                    :class="{ 'on': selectedDetail === detail.id }">
                    {{detail.name}}
                </a>
            </div>
            <div class="tab-cont">
                <div class="item" :class="{ 'on': selectedDetail === 0 }">
                    <h3>기초정보</h3>
                    <div class="gray-box">
                        <ul class="cb">
                            <li>
                                <h4><span class="fc1">01</span>사업자 관리</h4>
                                <p>회사의 기초 정보를 입력</p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>직원 관리</h4>
                                <p>사업자 직원 정보 입력</p>
                            </li>
                            <li>
                                <h4><span class="fc1">03</span>기초자료 관리</h4>
                                <p>거래처별기초금액, 상품기초재고,<br>자금관리(현금,은행,신용카드)기초액 입력</p>
                            </li>
                            <li>
                                <h4><span class="fc1">04</span>결제방법/경비 관리</h4>
                                <p>현금/예금 및 경비 유형 등록</p>
                            </li>
                            <li>
                                <h4><span class="fc1">05</span>신용카드 관리</h4>
                                <p>기업에서 사용하는 카드 및 카드사 등록</p>
                            </li>
                        </ul>
                    </div>
                    <div class="img-box">
                        <ul class="cb">
                            <li>
                                <h4>사업자 관리</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_1_1.png" alt="">
                            </li>
                            <li>
                                <h4>사업자 등록/수정하기</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_1_2.png" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item" :class="{ 'on': selectedDetail === 1 }">
                    <h3>상품</h3>
                    <div class="gray-box">
                        <ul>
                            <li>
                                <h4><span class="fc1">01</span>상품 관리</h4>
                                <p>판매할 상품에 대한 정보 입력</p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>상품별 특단가 관리</h4>
                                <p>거래처별 상품의 단가 등록<br>전체 단가 초기화 설정</p>
                            </li>
                            <li>
                                <h4><span class="fc1">03</span>예악 단가 관리</h4>
                                <p>정해진 일정에 맞춰 상품의 단가 변경<br>상품의 매입가 기준 변경단가 일괄적용 </p>
                            </li>
                            <li>
                                <h4><span class="fc1">04</span>상품 분류 설정</h4>
                                <p>상품 분류 설정 기능 4단계 구조로 설정<br>상품 선택 후 일괄 분류 가능</p>
                            </li>
                            <li>
                                <h4><span class="fc1">05</span>상품 주문 마감 그룹</h4>
                                <p>상품 그룹 지정 및 마감 지정 가능<br>주문 마감시간 D-1일 전, 시, 분 설정 가능</p>
                            </li>
                            <li>
                                <h4><span class="fc1">06</span>수입 물품유통 신고</h4>
                                <p>유니패스에 등록할 수입물품 등록 양식 제공</p>
                            </li>
                        </ul>
                    </div>
                    <div class="img-box">
                        <ul class="cb">
                            <li>
                                <h4>상품 관리</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_2_1.png" alt="">
                            </li>
                            <li>
                                <h4>상품별 특단가 관리</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_2_2.png" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item" :class="{ 'on': selectedDetail === 2 }">
                    <h3>거래처</h3>
                    <div class="gray-box">
                        <ul>
                            <li>
                                <h4><span class="fc1">01</span>거래처 관리</h4>
                                <p>매출처/매입처 구분 거래처 등록<br>담당자 일괄 변경 및 미사용 거래처 일괄 변경</p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>거래처별 특단가 관리</h4>
                                <p>거래처별 상품 단가 등록 확인 </p>
                            </li>
                            <li>
                                <h4><span class="fc1">03</span>거래처 분류 설정</h4>
                                <p>거래처 카테고리 4단계 구조로 지정 가능<br>거래처 선택 후 일괄 분류 가능</p>
                            </li>
                            <li>
                                <h4><span class="fc1">04</span>거래처 주문 마감 그룹</h4>
                                <p>거래처 그룹 지정 및 마감 지정 가능<br>주문 마감시간 D-1일 전, 시, 분 설정 가능</p>
                            </li>
                        </ul>
                    </div>
                    <div class="img-box">
                        <ul class="cb">
                            <li>
                                <h4>거래처 관리</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_3_1.png" alt="">
                            </li>
                            <li>
                                <h4>거래처별 특단가 관리</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_3_2.png" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item" :class="{ 'on': selectedDetail === 3 }">
                    <h3>주문관리</h3>
                    <div class="gray-box">
                        <ul>
                            <li>
                                <h4><span class="fc1">01</span>거래전표등록</h4>
                                <p>거래처별 전표등록<br>일괄기능(전표인쇄, 출고지시서, <br>거래일변경, 주문상품 가져오기, 전표삭제)</p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>수주등록</h4>
                                <p>수주/개별 일괄 등록<br>일괄기능(주문자료 가져오기, 담당자일괄변경)<br>특별기능(배송요청일변경, 엑셀불러오기)</p>
                            </li>
                            <li>
                                <h4><span class="fc1">03</span>수주현황</h4>
                                <p>수주 현황 및 매출 확정 가능<br>주문일, 배송요청일, 거래처, 상품별조회<br>출고지시서(상품별, 상품집계별, 거래처별)인쇄</p>
                            </li>
                            <li>
                                <h4><span class="fc1">04</span>발주등록</h4>
                                <p>발주 등록부터 매입 확정 가능<br>일괄기능(발주자료 가져오기, 발주서 일괄 인쇄)<br>수주, 발주에 따른 상품 조회 가능</p>
                            </li>
                            <li>
                                <h4><span class="fc1">05</span>발주현황</h4>
                                <p>발주현황 및 매입확정<br>발주서 인쇄/발송 <br>일괄기능(발주자료 가져오기, 발주서 일괄 인쇄)</p>
                            </li>
                            <li>
                                <h4><span class="fc1">06</span>매출 전표 원가 정리</h4>
                                <p>전표에 등록된 상품의 매입가 변경</p>
                            </li>
                            <li>
                                <h4><span class="fc1">07</span>매출전표 매출가 정리</h4>
                                <p>매출전표 확인 및 단가/이익률 일괄변경</p>
                            </li>
                            <li>
                                <h4><span class="fc1">08</span>자료 일괄 등록</h4>
                                <p>매출/매입 전표 구분을 기준으로<br>거래전표 일괄 등록</p>
                            </li>
                        </ul>
                    </div>
                    <div class="img-box">
                        <ul class="cb">
                            <li>
                                <h4>거래전표등록</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_4_1.png" alt="">
                            </li>
                            <li>
                                <h4>수주현황</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_4_2.png" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item" :class="{ 'on': selectedDetail === 4 }">
                    <h3>정보조회</h3>
                    <div class="gray-box">
                        <ul>
                            <li>
                                <h4><span class="fc1">01</span>거래처별 원장</h4>
                                <p>거래처별 거래내역 조회<br>거래처 지급 단가 조회 </p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>미수/미지급현황</h4>
                                <p>기간별 거래처 매출 잔액 및 이익률 확인<br>전월 매출 및 이익율 확인 비교 가능</p>
                            </li>
                            <li>
                                <h4><span class="fc1">03</span>매입/매출 내역서</h4>
                                <p>상품 입/출고 내역 조회<br>단가 및 매입가 확인가능<br>(박스/낱개 단가, 박스/낱개 매입가, 등급단가)</p>
                            </li>
                        </ul>
                    </div>
                    <div class="img-box">
                        <ul class="cb">
                            <li>
                                <h4>거래처별 원장</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_5_1.png" alt="">
                            </li>
                            <li>
                                <h4>미수/미지급현황</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_5_2.png" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item" :class="{ 'on': selectedDetail === 5 }">
                    <h3>재고관리</h3>
                    <div class="gray-box">
                        <ul>
                            <li>
                                <h4><span class="fc1">01</span>상품별 재고 현황</h4>
                                <p>상품별 입출고 집계 내역 조회</p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>재고처별 재고현황</h4>
                                <p>재고처별 상품의 재고 수량 조회<br>선택일 기준 재고 수량, 재고금액 조회</p>
                            </li>
                            <li>
                                <h4><span class="fc1">03</span>실사 재고 조정</h4>
                                <p>실사 후 재고처별 재고 수량 조정<br>실사 재고 조정값 삭제, 재고초기화, <br>자료 업로드 제공</p>
                            </li>
                            <li>
                                <h4><span class="fc1">04</span>적정 재고 관리</h4>
                                <p>재고 부족 상품 조회 및 발주 등록</p>
                            </li>
                            <li>
                                <h4><span class="fc1">05</span>상품별 거래 수불</h4>
                                <p>상품별 거래내역 조회<br>기간 내 발생한 상품 매입/매출내역 재고수량 조회</p>
                            </li>
                            <li>
                                <h4><span class="fc1">06</span>재고처 간 이동등록</h4>
                                <p>재고처간 상품 이동 등록</p>
                            </li>
                            <li>
                                <h4><span class="fc1">07</span>월별재고 마감 (이동평균)</h4>
                                <p>이동평균법으로 재고단가 조회 </p>
                            </li>
                        </ul>
                    </div>
                    <div class="img-box">
                        <ul class="cb">
                            <li>
                                <h4>상품별 재고 현황</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_6_1.png" alt="">
                            </li>
                            <li>
                                <h4>실사 재고 조정</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_6_2.png" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item" :class="{ 'on': selectedDetail === 6 }">
                    <h3>회계관리</h3>
                    <div class="gray-box">
                        <ul>
                            <li>
                                <h4><span class="fc1">01</span>회계전표 등록</h4>
                                <p>거래처 결제금액(입금/지급), 경비, <br>입출금 내역 등록, 입금/지급 회계 구분 제공 <br>거래처/자금관리 잔액 조회</p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>회계전표 현황</h4>
                                <p>기간별 전표 내역 확인</p>
                            </li>
                            <li>
                                <h4><span class="fc1">03</span>손익현황</h4>
                                <p>거래처별 손익 경비조회<br>(기간 내 발생한 순 매출액, 이익률, 집계비용 조회)<br>회사별 총계 이익 금액 확인 가능</p>
                            </li>
                            <li>
                                <h4><span class="fc1">04</span>결제방법/경비원장</h4>
                                <p>계정과목별 거래내역 조회<br>기간별 발생한 자금 변동 및 잔액 조회 </p>
                            </li>
                            <li>
                                <h4><span class="fc1">05</span>일/월계표</h4>
                                <p>거래처별 거래내역 집계금액 조회<br>일/월 선택에 따른 일/월계표 조회</p>
                            </li>
                            <li>
                                <h4><span class="fc1">06</span>현금출납장</h4>
                                <p>현금 자금관리별 입금/지급금액 잔액 조회</p>
                            </li>
                            <li>
                                <h4><span class="fc1">07</span>신용카드 현황</h4>
                                <p>신용카드 자금관리별 입금/지급 잔액 조회</p>
                            </li>
                            <li>
                                <h4><span class="fc1">08</span>거래처별 잔액 현황</h4>
                                <p>거래처별 매출/매입 금액 조회</p>
                            </li>
                            <li>
                                <h4><span class="fc1">09</span>일일마감</h4>
                                <p>등록한 모든 거래 확정을 위한 마감<br>자동마감시간 설정 지원 </p>
                            </li>
                        </ul>
                    </div>
                    <div class="img-box">
                        <ul class="cb">
                            <li>
                                <h4>회계전표 등록</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_7_1.png" alt="">
                            </li>
                            <li>
                                <h4>회계전표 현황</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_7_2.png" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item" :class="{ 'on': selectedDetail === 7 }">
                    <h3>세금관리</h3>
                    <div class="gray-box">
                        <ul>
                            <li>
                                <h4><span class="fc1">01</span>(세금)계산서 발급</h4>
                                <p>거래처별 세금계산서 조회 등록 발급 가능<br>매출내역 기준, 세금계산서 발행</p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>전자(세금)계산서 발급</h4>
                                <p>홈택스 등록 가능한 양식 제공</p>
                            </li>
                        </ul>
                    </div>
                    <div class="img-box">
                        <ul class="cb">
                            <li>
                                <h4>(세금)계산서 발급</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_8_1.png" alt="">
                            </li>
                            <li>
                                <h4>전자(세금)계산서 발급</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_8_2.png" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item" :class="{ 'on': selectedDetail === 8 }">
                    <h3>인사관리(예정)</h3>
                    <div class="gray-box">
                        <ul>
                            <li>
                                <h4><span class="fc1">01</span>급여 관리</h4>
                                <p>직원 급여 관리 </p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>증명서 발급</h4>
                                <p>직원 각종 증명서 발급</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item" :class="{ 'on': selectedDetail === 9 }">
                    <h3>관리자</h3>
                    <div class="gray-box">
                        <ul>
                            <li>
                                <h4><span class="fc1">01</span>거래내역 변경현황 (예정)</h4>
                                <p>메뉴 변경 내역 확인 </p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>사용자등록/권한 관리</h4>
                                <p>사용자등록 및 사용자별 권한 관리</p>
                            </li>
                            <li>
                                <h4><span class="fc1">03</span>공지사항 관리 (예정)</h4>
                                <p>거래처 안내를 위한 공지 등록 관리</p>
                            </li>
                        </ul>
                    </div>
                    <div class="img-box">
                        <ul class="cb">
                            <li>
                                <h4>사용자등록/권한관리</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_10_1.png" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item" :class="{ 'on': selectedDetail === 10 }">
                    <h3>환경설정</h3>
                    <div class="gray-box">
                        <ul>
                            <li>
                                <h4><span class="fc1">01</span>프로그램 설정</h4>
                                <p>상품/거래처 코드, 합계금액/부가세 표기 여부<br>상품 수량 공통 설정 및 회사별 기본값 설정</p>
                            </li>
                            <li>
                                <h4><span class="fc1">02</span>마이페이지</h4>
                                <p>회원정보수정, 이용/결제관리, 메시지 충전</p>
                            </li>
                        </ul>
                    </div>
                    <div class="img-box">
                        <ul class="cb">
                            <li>
                                <h4>프로그램 설정</h4>
                                <img src="@/assets/desktop/images/pic/img_function_section2_11_1.png" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section section3 section-promotion">
        <div class="inner">
            <div class="orange-box">
                <h3>완벽한 유통, 해답은 <strong>마켓봄 프로</strong></h3>
                <a @click="signIn()" class="btn type6 size3">1개월 무료 이용하기</a>
            </div>
        </div>
    </div>

    <div class="section section4 section-franchise">
        <div class="inner">
            <h4>혹시 <strong class="bold">프랜차이즈 본사</strong>이신가요?</h4>
            <p>프랜차이즈 본사를 위한 수발주 서비스 <strong class="bold">마켓봄</strong>에 대해서 알아보세요. </p>
            <a target="_blank" :href="marketbomLink"
                @click="gtag('마켓봄 메인 페이지 이동', 'guide_marketbom')"
                class="btn type4 size2">마켓봄 바로가기</a>
        </div>
    </div>

</div>

</template>

<script>
import LinkMixin from '@/mixins/linkMixin.vue';
import GtagMixin from '@/mixins/gtagMixin.vue';

export default {
  mixins: [LinkMixin, GtagMixin],
  data: () => ({
    selectedDetail: null,
    visibleMenu: false,
    details: [
      { name: '기초 정보', id: 0 },
      { name: '상품', id: 1 },
      { name: '거래처', id: 2 },
      { name: '주문관리', id: 3 },
      { name: '정보조회', id: 4 },
      { name: '재고관리', id: 5 },
      { name: '회계관리', id: 6 },
      { name: '세금관리', id: 7 },
      { name: '인사관리', id: 8 },
      { name: '관리자', id: 9 },
      { name: '환경설정', id: 10 },
    ],
  }),
  methods: {
    toggleMenu() {
      this.visibleMenu = !this.visibleMenu;
    },
    selectDetail(detail) {
      this.selectedDetail = detail.id;
      this.gtag(detail.name, 'guide');
    },
    signIn() {
      this.goToSignin();
      this.gtag('1개월무료이용하기', 'guide_month', '회원가입');
    },
  },
  mounted() {
    this.selectDetail(this.details[0]);
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/desktop/css/common/common.scss';
.mkb-desktop {
    font-family: "Noto Sans KR", sans-serif;
}

</style>
