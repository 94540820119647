import Vue from 'vue';
import VueMobileDetection from 'vue-mobile-detection';
import VueGtm from '@gtm-support/vue2-gtm';

import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.use(VueMobileDetection);
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
