<template>
  <div class="mkb-desktop" @click="toggleMenu">
    <div id="wrap">
      <div id="header">
        <div class="inner cb">
          <h1><a @click="linkTo('/')"><img src="@/assets/desktop/images/logo/logo1.svg" alt="마켓봄 프로"></a></h1>

          <ul class="gnb cb">
            <li v-for="(tab, i) in tabs" :key="i">
              <a :class="{on: tab.path === path}" @click="clickTab(tab.path)">{{tab.name}}</a>
            </li>
          </ul>

          <div class="sign-area cb">
            <a href="tel:15229559" @click="gtag('고객센터', 'gnb')"
              class="btn-cs-center">1522-9559</a>
            <a @click="signIn()" class="btn type2 size1">회원가입</a>
            <a @click="login()" class="btn type1 size1">로그인</a>
          </div>

          <button type="button" class="btn-menu"><span>메뉴</span></button>
          <div class="layer-gnb cb" :class="{on: isVisibleMenu}" ref="menu">
            <div class="col type1">
              <div class="label">
                <a target="_blank" @click="gtag('마켓봄 메인 페이지 이동', 'gnb')"
                  :href="marketbomLink">마켓봄</a>
              </div>
              <ul>
                <li>
                  <a target="_blank" @click="gtag('마켓봄 소개 페이지', 'gnb')"
                    :href="marketbomInfoLink">마켓봄 서비스 소개</a>
                </li>
                <li>
                  <a target="_blank" @click="gtag('마켓봄 도매 소개페이지', 'gnb')"
                    :href="marketbomWholesalesInfoLink">마켓봄 도매 서비스 소개</a>
                </li>
              </ul>
            </div>
            <div class="col type2">
              <div class="label">마켓봄 프로</div>
              <ul>
                <li>
                  <a @click="clickTab('/')">마켓봄 프로 서비스 소개</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <router-view @linkTo="linkTo" />
      <div id="footer">
        <div class="inner">
          <h2><img src="@/assets/desktop/images/logo/logo2.svg" alt="마켓봄 프로"></h2>
          <div class="company-info">
            <strong class="bold">(주)마켓보로</strong><span>대표이사 : 임사성</span><span>사업장 주소 : 경기도 성남시 분당구 판교로 323,
              8층(투썬벤처포럼빌딩)</span><br>
            <span>대표번호 : 031-698-3417</span><span>info@marketbom.com</span><span>개인정보담당자 : 오진영 이사 (
              info@marketbom.com)</span><br>
            <span>사업자등록번호 : 479-86-00423</span><span>통신판매업신고 : 제2018-성남분당-0313호</span>
          </div>
          <div class="copyright">Copyright marketboro Co., Ltd. All rights reserved.</div>
          <a href="https://www.whelper.co.kr/client/main.do" target="_blank" class="btn-remote-control">원격상담신청</a>
        </div>
      </div>

    </div>
    <!-- 레이어 - 상담 신청 -->
    <div v-if="isMain" class="layer-easy-cs" @click="scrollToCounselingForm();">간편 상담 신청</div>
  </div>
</template>

<script>
import $ from 'jquery';
import LinkMixin from '@/mixins/linkMixin.vue';
import GtagMixin from '@/mixins/gtagMixin.vue';

export default {
  mixins: [LinkMixin, GtagMixin],
  data: () => ({
    inProgress: false,
    form: {
      vendorName: '',
      customerName: '',
      mobile: '',
      month: '',
      day: '',
      hour: '',
    },
    isVisibleRemoteCounselPopup: false,
    isVisibleMenu: false,
    path: '/',
    tabs: [
      { name: '요금 안내', path: '/price' },
      { name: '상세 기능', path: '/guide' },
    ],
  }),
  computed: {
    isMain() {
      return this.path === '/';
    },
  },
  methods: {
    toggleMenu(event) {
      const { target } = event;
      if (target.className === 'btn-menu'
        || target.parentElement?.className === 'btn-menu') {
        this.isVisibleMenu = !this.isVisibleMenu;
      } else {
        this.isVisibleMenu = false;
      }
      if (this.isVisibleMenu) {
        $('.layer-gnb').stop().slideDown(300);
      } else {
        $('.layer-gnb').stop().slideUp(300);
      }
    },
    scrollToCounselingForm() {
      const applyCounselingElem = document.getElementById('applyCounseling');
      if (applyCounselingElem) {
        this.scrollTo(applyCounselingElem);
        this.gtag('문의남기기이동', 'main');
      }
    },
    initForm() {
      this.form.vendorName = '';
      this.form.customerName = '';
      this.form.mobile = '';
      this.form.month = '';
      this.form.day = '';
      this.form.hour = '';
    },
    linkTo(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
        this.path = path;
      }
    },
    scrollTo(elem) {
      const objT = $(elem).offset().top;
      const headerH = $('#header').outerHeight(true);
      $('html, body').stop().animate({ scrollTop: objT - headerH }, 600);
    },
    clickTab(tabPath) {
      this.linkTo(tabPath);
      if (tabPath === '/price') {
        this.gtag('요금안내', 'gnb');
      } else if (tabPath === '/guide') {
        this.gtag('상세기능 안내', 'gnb');
      } else if (tabPath === '/') {
        this.gtag('마켓봄 프로 메인', 'gnb');
      }
    },
    signIn() {
      this.goToSignin();
      this.gtag('회원가입', 'gnb_signup');
    },
    login() {
      this.goToLogin();
      this.gtag('로그인', 'gnb');
    },
  },
  mounted() {
    this.path = this.$route.path;
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/desktop/css/common/common.scss';
.mkb-desktop {
  min-width: 1240px;
  word-spacing: -1.2px;
}

.layer-cscenter {
    display: none;
}

.layer-cscenter.on {
    display: block;
}

.main-container {
  word-spacing: -1.2px !important;
}

button:disabled {
  a {
    opacity: 0.3 !important;
  }
}

</style>
