<template>
    <div id="container" class="price-container">
        <h2 class="blind">본문 내용</h2>

        <div class="section section1">
            <h3>마켓봄 프로의 <strong class="bold">기본 3無 요금</strong></h3>
            <p>마켓봄 프로는 이용료 외에 <br>추가 비용이 발생하지 않습니다.</p>
            <div class="circle-box">
                <div class="item">
                    <span>가입비</span>
                    <strong class="fc1">0원</strong>
                </div>
                <div class="item">
                    <span>초기 세팅비</span>
                    <strong class="fc1">0원</strong>
                </div>
                <div class="item">
                    <span>거래처 추가비</span>
                    <strong class="fc1">0원</strong>
                    <em>(무제한 가능)</em>
                </div>
            </div>
        </div>

        <div class="section section2">
            <h3><strong>마켓봄 프로 요금제</strong>를 <br>확인해보세요.</h3>
            <div class="table-box">
                <table>
                    <colgroup>
                        <col width="*" />
                        <col width="*" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col">아이디<br>이용 요금</th>
                            <th scope="col">월간<br>이용료</th>
                            <th scope="col">최소<br>아이디당 요금</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td>1~3개</td>
                          <td>11만원</td>
                          <td>3만6천원</td>
                      </tr>
                      <tr>
                          <td>4~5개</td>
                          <td>17만원</td>
                          <td>3만4천원</td>
                      </tr>
                      <tr>
                          <td>6~10개</td>
                          <td>32만원</td>
                          <td>3만2천원</td>
                      </tr>
                      <tr>
                          <td>11~15개</td>
                          <td>47만원</td>
                          <td>3만1천원</td>
                      </tr>
                      <tr>
                          <td>16~20개</td>
                          <td>60만원</td>
                          <td>3만원</td>
                      </tr>
                      <tr>
                          <td>21~25개</td>
                          <td>74만원</td>
                          <td>2만9천원</td>
                      </tr>
                      <tr>
                          <td>26~30개</td>
                          <td>87만원</td>
                          <td>2만9천원</td>
                      </tr>
                      <tr>
                          <td>31~40개</td>
                          <td>110만원</td>
                          <td>2만7천원</td>
                      </tr>
                      <tr>
                          <td>41~50개</td>
                          <td>130만원</td>
                          <td>2만6천원</td>
                      </tr>
                      <tr>
                          <td>51개 이상</td>
                          <td>140만원</td>
                          <td>2만6천원 이하</td>
                      </tr>
                    </tbody>
                </table>
            </div>
            <p class="sub-info">(VAT 별도)</p>
        </div>

        <div class="section section3">
            <h3><strong>마켓봄 프로</strong> <br>VS 타사 요금 비교</h3>
            <div class="img-box">
                <img src="@/assets/mobile/images/pic/img_price_section3.png" alt="마켓봄 프로 VS 타사 요금 비교">
            </div>
        </div>

        <div class="section section4 section-promotion">
            <a @click="signIn()">마켓봄 프로 <strong>1개월 무료 이용하기</strong></a>
        </div>

        <div class="section section5 section-franchise">
            <h3>혹시 <br><strong>프랜차이즈 본사</strong>이신가요?</h3>
            <p>프랜차이즈 본사를 위한 수발주 서비스 <br><strong class="bold">마켓봄</strong>에 대해서 알아보세요. </p>
            <a target="_blank" :href="marketbomLink"
                @click="gtag('마켓봄 메인 페이지 이동', 'price_marketbom')"
                class="btn type3 size2">마켓봄 바로가기</a>
        </div>

    </div>
</template>

<script>
import LinkMixin from '@/mixins/linkMixin.vue';
import GtagMixin from '@/mixins/gtagMixin.vue';

export default {
  mixins: [LinkMixin, GtagMixin],
  methods: {
    signIn() {
      this.goToSignin();
      this.gtag('1개월무료이용하기', 'price_month');
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/mobile/css/common/common.scss';
@import '@/assets/mobile/css/plugin/slick.scss';

.mkb-mobile {
    font-family: "Noto Sans KR", sans-serif;
}

</style>
