<template>
  <div>
    <Desktop v-if="!isMobile"/>
    <Mobile  v-if="isMobile"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Desktop from '@/components/desktop/Detail.vue';
import Mobile from '@/components/mobile/Detail.vue';

export default {
  components: {
    Desktop, Mobile,
  },
  data: () => ({
    isMobile: false,
  }),
  beforeMount() {
    this.isMobile = this.$isMobile();
  },
};
</script>
