<template>
<div id="container" class="main-container">
    <h2 class="blind">본문 내용</h2>

    <div class="section section1">
        <h3>완벽한 유통, 해답은<strong>마켓봄 프로</strong></h3>
        <div class="circle-box">
            <div class="item">수발주</div>
            <div class="item">ERP</div>
            <div class="item">다중구조</div>
        </div>
        <a @click="signIn('main')" class="btn type1 size2">1개월 무료 이용하기</a>
    </div>

    <div class="section section2">
        <h3><strong>마켓봄 프로</strong>가<br>꼭 필요한 유통사들은</h3>
        <div class="text-list">
            <div class="item">
                <p>여러개의 사업자와 대리점으로<strong><em class="fc1">다중구조</em>가 필요한 유통사</strong></p>
                <img src="@/assets/mobile/images/pic/img_main_section2_1.png" alt="">
            </div>
            <div class="item">
                <p>수발주 외에 재고, 회계 등의<strong><em class="fc1">ERP 기능</em>이 필요한 유통사</strong></p>
                <img src="@/assets/mobile/images/pic/img_main_section2_2.png" alt="">
            </div>
            <div class="item">
                <p>여러개의 전산 프로그램을<strong><em class="fc1">통합 운영</em>해야 하는 유통사</strong></p>
                <img src="@/assets/mobile/images/pic/img_main_section2_3.png" alt="">
            </div>
        </div>
    </div>

    <div class="section section3">
        <h3><strong>마켓봄 프로</strong>의<br>완벽한 8가지 해답</h3>
        <div class="text-box">
            <div class="item type1">
                <dl>
                    <dt>비싼 가입비, 설치비, 세팅비<strong class="fc1">모두 무료!</strong></dt>
                    <dd>거래처 추가도 <strong>제한 없이 무료!</strong><br>타사 대비 최대 <strong>70% 저렴</strong>하게 <br>이용할 수 있습니다.
                    </dd>
                </dl>
            </div>
            <div class="item type2">
                <dl>
                    <dt>복잡한 유통사/대리점 구조<strong class="fc1">다중구조로 쉬운 관리</strong></dt>
                    <dd>관리 중인 <strong>여러 유통사를</strong><br><strong>한곳에서 관리!</strong><br>대리점도 주문 받을 수 있는<br><strong>확장
                            구조 가능!</strong></dd>
                </dl>
            </div>
            <div class="item type3">
                <dl>
                    <dt>외상 거래로 미수금 증가<br>마켓봄 프로에서는<strong class="fc1">미수금 제로!</strong></dt>
                    <dd><strong>다양한 선결제 방식</strong>(가상계좌,<br>신용카드 등)과 <strong>외상 한도 설정</strong>으로<br>미수금 관리가 가능합니다.
                    </dd>
                </dl>
            </div>
            <div class="item type4">
                <dl>
                    <dt>엑셀 데이터도!<br>타사 프로그램 데이터도!<strong class="fc1">한번에 업로드!</strong></dt>
                    <dd><strong>한번에 업로드 해서 빠르게</strong><br><strong>등록</strong>할 수 있고, 수동 등록으로<br>인한 오류 방지가 가능합니다.
                    </dd>
                </dl>
            </div>
            <div class="item type5">
                <dl>
                    <dt>매일 반복되는 주문/단가 관리<strong class="fc1">간편한 맞춤 관리</strong></dt>
                    <dd>원하는 일자에 자동 변경이 가능한<br><strong>예약단가관리, 상품별/거래처별로</strong><br><strong>최적화된 관리</strong>가 가능합니다.
                    </dd>
                </dl>
            </div>
            <div class="item type6">
                <dl>
                    <dt>자유로운 설정/인쇄는 기본!<strong class="fc1">맞춤 커스텀 제공</strong></dt>
                    <dd>현장에 최적화된 인쇄 설정 가능<br><strong>다양한 검색 조건으로 맞춤 데이터</strong><br><strong>출력</strong>이 가능합니다.</dd>
                </dl>
            </div>
            <div class="item type7">
                <dl>
                    <dt>카톡, 문자, 전화로 불편한 주문<strong class="fc1">주문앱으로 간편하게</strong></dt>
                    <dd>거래처(식당)에게 주문용 모바일<br>앱을 지원하여 <strong>간편하게 주문을</strong><br><strong>취합</strong>할 수 있습니다.</dd>
                </dl>
            </div>
            <div class="item type8">
                <dl>
                    <dt>영업사원이 직접 방문하여<br>세팅부터 데이터 이관까지<strong class="fc1">무료 지원</strong></dt>
                    <dd><strong>전화/원격</strong>을 통해 자세한 안내를<br>받으시거나, 영업사원이 <strong>직접 방문하여</strong><br><strong>무료로
                            교육</strong>을 진행해 드립니다.</dd>
                </dl>
            </div>
        </div>
        <div class="btn-wrap">
            <p>마켓봄 프로의 <br>더 많은 기능을 확인해 보세요</p>
            <a @click="linkTo('/guide')" class="btn type1 size2">상세 기능 안내</a>
        </div>
    </div>

    <div class="section section4">
        <h3><strong>마켓봄 프로</strong> VS 타사 요금 비교</h3>
        <div class="img-box">
            <img src="@/assets/mobile/images/pic/img_main_section4_1.png" alt="마켓봄 프로 VS 타사 요금 비교">
        </div>
        <div class="btn-wrap">
            <p>마켓봄 프로의 <br>저렴한 이용료를 확인해 보세요.</p>
            <a @click="linkTo('/price')" class="btn type2 size2">요금 안내</a>
        </div>
    </div>

    <div class="section section5 section-promotion">
        <a @click="signIn('banner')">마켓봄 프로 <strong>1개월 무료 이용하기</strong></a>
    </div>

    <div class="section section6">
        <h3>궁금하신 내용은<strong>바로 전화주세요!</strong></h3>
        <div class="insert-box">
            <div class="row">
                <strong class="label">이름</strong>
                <div class="input-text">
                    <label>
                        <input type="text" v-model="form.vendorName" :disabled="inProgress">
                    </label>
                </div>
            </div>
            <div class="row">
                <strong class="label">연락처 or 이메일</strong>
                <div class="input-text">
                    <label>
                        <input type="text" v-model="form.mobile" :disabled="inProgress">
                    </label>
                </div>
            </div>
            <div class="row">
                <strong class="label">궁금하신 내용을 작성해주세요.</strong>
                <div class="input-textarea">
                    <label>
                        <textarea v-model="form.contents" :disabled="inProgress"></textarea>
                    </label>
                </div>
            </div>
            <div class="input-checkbox">
                <label>
                    <input type="checkbox" v-model="form.checked" :disabled="inProgress">
                    <span>개인정보 처리방침에 동의합니다.</span>
                </label>
                &nbsp;
                <a style="font-size:15px; color:#888;" :href="marketbomLink + '/term/privacy'" target="_blank">[상세보기]</a>
            </div>
            <div class="btn-wrap">
                <button type="button" class="btn type1 size3" :disabled="inProgress" @click="applyCounseling()">
                    문의 남기기
                </button>
            </div>
        </div>
        <div class="text-box">
            <div class="cs-center">
                <a href="tel:15229559" class="open-sans">1522-9559</a>
                <p>평일 09:00~18:00<span>(점심시간 12:30~13:30)</span></p>
            </div>
            <div class="contact-us">
                <a href="mailto:info@marketbom.com" class="btn-mail">info@marketbom.com</a>
                <div class="address">경기도 성남시 분당구 판교로 323<br>투썬벤처포럼 8층 마켓보로</div>
            </div>
        </div>
    </div>

    <div class="section section7 section-franchise">
        <h3>혹시 <br><strong>프랜차이즈 본사</strong>이신가요?</h3>
        <p>프랜차이즈 본사를 위한 수발주 서비스 <br><strong class="bold">마켓봄</strong>에 대해서 알아보세요. </p>
        <a target="_blank" :href="marketbomLink"
            @click="gtag('마켓봄 메인 페이지 이동', 'marketbom')"
            class="btn type3 size2">마켓봄 바로가기</a>
    </div>

</div>
</template>

<script>
import postWithAxios from '@/common/api';
import {
  isValidName, isValidContact, isValidPhoneNumber, toOnlyNumberString,
} from '@/common/util';

import LinkMixin from '@/mixins/linkMixin.vue';
import GtagMixin from '@/mixins/gtagMixin.vue';

export default {
  mixins: [LinkMixin, GtagMixin],
  data: () => ({
    inProgress: false,
    form: {
      vendorName: '',
      mobile: '',
      contents: '',
      checked: false,
    },
  }),
  methods: {
    linkTo(path) {
      this.$emit('linkTo', path);
      if (path === '/guide') {
        this.gtag('상세기능안내', 'main');
      } else if (path === '/price') {
        this.gtag('요금안내', 'main');
      }
    },
    applyCounseling() {
      if (this.inProgress) {
        return;
      }
      const {
        vendorName, mobile, contents, checked,
      } = this.form;
      console.log(this.form);
      if (!isValidName(vendorName)) {
        alert('이름이 유효하지 않습니다.');
        return;
      }
      if (!isValidContact(mobile)) {
        alert('연락처가 유효하지 않습니다.');
        return;
      }

      if (!vendorName || !mobile || !contents || !checked) {
        alert('이름, 연락처, 문의사항, 약관동의 필수 입력 사항 입니다.');
        return;
      }
      this.inProgress = true;
      postWithAxios('api/question', {
        vendor_name: vendorName,
        mobile: isValidPhoneNumber(mobile) ? toOnlyNumberString(mobile) : mobile,
        contents,
        type: 'PRO',
      }).then((res) => {
        const { result } = res;
        if (result) {
          alert('접수가 완료되었습니다.');
          this.initForm();
        } else {
          alert('현재 상담 접수가 어려워 전화를 주시면 빠른 안내 해드리겠습니다.');
        }
      }, (err) => {
        alert(`현재 상담 접수가 어려워 전화를 주시면 빠른 안내 해드리겠습니다.${
          err?.message ? `\n(${err.message})` : ''}`);
      }).finally(() => {
        this.inProgress = false;
      });
      this.gtag('문의사항전송', 'main');
    },
    initForm() {
      this.form.vendorName = '';
      this.form.mobile = '';
      this.form.contents = '';
      this.form.checked = false;
    },
    signIn(type) {
      this.goToSignin();
      this.gtag('1개월무료이용하기', type === 'main' ? 'main_month' : 'banner_month', '회원가입');
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/mobile/css/common/common.scss';
@import '@/assets/mobile/css/plugin/slick.scss';

.mkb-mobile {
    font-family: "Noto Sans KR", sans-serif;
}

button:disabled {
  opacity: 0.3 !important;
}

</style>
