export default function isIE() {
  const ua = window.navigator?.userAgent || '';

  return /MSIE|Trident/.test(ua);
}

export function toOnlyNumberString(value) {
  return value.replace(/[^0-9]/g, '');
}

export function isValidName(value) {
  const regex = /[^a-zA-Z가-힣0-9\s\\(\\)]+/g;
  return !regex.test(value);
}

export function isValidPhoneNumber(value) {
  const regex = /^[0-9|-]{8,16}$/;
  const cleanInput = toOnlyNumberString(value);
  return regex.test(value) && cleanInput.length <= 12 && cleanInput.length >= 8;
}

export function isValidEmail(value) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

export function isValidContact(value) {
  return isValidPhoneNumber(value) || isValidEmail(value);
}
