import axios from 'axios';

axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// HTTP 에러 생성.
function createHttpError(error) {
  return {
    code: error?.response?.status || 999,
    message: error?.response?.data?.message || error.message,
  };
}

export default async function postWithAxios(url, params) {
  try {
    const formData = new FormData();
    if (params) {
      Object.keys(params).forEach((key) => {
        const value = params[key];
        if (Array.isArray(value)) {
          value.forEach((v) => {
            formData.append(`${key}[]`, v);
          });
        } else {
          formData.append(key, params[key]);
        }
      });
    }
    const res = await axios.post(url, formData, {
      timeout: 60000,
      headers: {
        Accept: 'application/json, text/javascript, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const { status, data } = res;
    if (status === 200) {
      return data;
    }
    console.log('res', res);
    return Promise.reject(new Error(data.message || '서버와 통신에 실패했습니다.'));
  } catch (error) {
    return Promise.reject(createHttpError(error));
  }
}
