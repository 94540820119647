<template>
  <div>
    <Desktop v-if="!isMobile" @linkTo="linkTo"/>
    <Mobile  v-if="isMobile" @linkTo="linkTo"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Desktop from '@/components/desktop/Main.vue';
import Mobile from '@/components/mobile/Main.vue';

export default {
  components: {
    Desktop, Mobile,
  },
  data: () => ({
    isMobile: false,
  }),
  methods: {
    linkTo(path) {
      this.$emit('linkTo', path);
    },
  },
  beforeMount() {
    this.isMobile = this.$isMobile();
  },
};
</script>
