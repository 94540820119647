<script>
export default {
  methods: {
    gtag(action, category, label, value) {
      try {
        const detail = {};
        if (category !== undefined) {
          detail.event_category = category;
        }
        if (label !== undefined) {
          detail.event_label = label;
        }
        if (value !== undefined) {
          detail.value = value;
        }
        this.$gtag.event(action, detail);
      } catch (e) {
        console.error(new Error('GA 이벤트 기록 실패'));
      }
      try {
        this.$gtm.trackEvent({
          event: 'click',
          category,
          action,
          label,
        });
      } catch (e) {
        console.error(new Error('GTM 이벤트 기록 실패'));
      }
    },
  },
};
</script>
