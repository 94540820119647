<script>
import isIE from '@/common/util';

export default {
  data: () => ({
    loginLink: process.env.VUE_APP_LOGIN_URL,
    signupLink: process.env.VUE_APP_SIGNUP_URL,
    marketbomLink: process.env.VUE_APP_MARKETBOM_URL,
    marketbomInfoLink: process.env.VUE_APP_MARKETBOM_INFO_URL,
    marketbomWholesalesInfoLink: process.env.VUE_APP_MARKETBOM_WHOLESALES_INFO_URL,
  }),
  methods: {
    goToLogin() {
      if (isIE()) {
        alert('마켓봄 프로 가입/로그인은 크롬 브라우저에서 이용해주세요.');
      } else {
        window.open(this.loginLink, '_blank');
      }
    },
    goToSignin() {
      if (isIE()) {
        alert('마켓봄 프로 가입/로그인은 크롬 브라우저에서 이용해주세요.');
      } else {
        window.open(this.signupLink, '_blank');
      }
    },
  },
};
</script>
