import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '@/components/common/Main.vue';
import Detail from '@/components/common/Detail.vue';
import Price from '@/components/common/Price.vue';
import Home from '@/views/Home.vue';
// import Mobile from '../views/Mobile.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      { path: 'guide', component: Detail },
      { path: 'price', component: Price },
      { path: '', component: Main },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
