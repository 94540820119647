<template>
  <div id="app" class="mkb-app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
    isMobile: false,
  }),
  beforeMount() {
    this.isMobile = this.$isMobile();
  },
};
</script>

<style lang="scss">
  @import '@/assets/css/common/reset.scss';
  @import '@/assets/css/common/font.scss';

  html {
    -webkit-text-size-adjust: 100%;
  }

  body {
    font-size: 14px;
    color: #000;
    font-family: "Noto Sans KR", sans-serif;
    line-height: 1.3;
  }

  a {
    cursor: pointer;
  }

  body.scroll-fixed {
    overflow: hidden;
  }
</style>
