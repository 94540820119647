<template>
  <div class="mkb-mobile" @click="toggleMenu">
    <div id="wrap">

      <div id="header">
        <div class="top">
          <h1><a @click="linkTo('/')"><img src="@/assets/mobile/images/logo/logo1.svg" alt="마켓봄 프로"></a></h1>
          <div class="sign-area">
            <a href="tel:15229559" @click="gtag('고객센터', 'gnb')"
              class="btn-cs-center">1522-9559</a>
            <a @click="login()" class="btn type1 size1">로그인</a>
          </div>
          <button type="button" class="btn-menu"><span>메뉴</span></button>
        </div>
        <div class="gnb">
          <ul>
            <li v-for="(tab, i) in tabs" :key="i">
              <a :class="{on: tab.path === path}" @click="clickTab(tab.path)">{{tab.name}}</a>
            </li>
          </ul>
        </div>
      </div>

      <router-view @linkTo="linkTo" />

      <div id="footer">
        <div class="company-info">
          <strong class="bold">(주)마켓보로</strong>
          <span>대표이사 : 임사성</span>
          <span>경기도 성남시 분당구 판교로 323, 8층(투썬벤처포럼빌딩)</span>
          <span>대표번호 : 031-698-3417 <em>info@marketbom.com</em></span>
          <span>개인정보담당자 : 오진영 이사 ( info@marketbom.com)</span>
          <span>사업자등록번호 : 479-86-00423</span>
          <span>통신판매업신고 : 제2018-성남분당-0313호</span>
        </div>
        <div class="copyright">Copyright marketboro Co., Ltd. All rights reserved.</div>
        <a href="https://www.whelper.co.kr/client/main.do" target="_blank" class="btn-remote-control">원격상담신청</a>
      </div>
    </div>

    <!-- 아이폰 스크롤 버그로 인해 최상위 위치해야합니다. -->
    <div class="layer-gnb" :class="{on: isVisibleMenu}">
      <div class="inner">
        <button type="button" class="btn-close">닫기</button>
        <div class="row type1">
          <div class="label">
            <a target="_blank" :href="marketbomLink"
              @click="gtag('마켓봄 메인 페이지 이동', 'gnb')">마켓봄</a>
          </div>
          <div class="img-box">
            <img src="@/assets/mobile/images/pic/img_gnb1.png" alt="">
          </div>
          <ul>
            <li>
              <a target="_blank" :href="marketbomInfoLink"
                @click="gtag('마켓봄 소개 페이지', 'gnb')">마켓봄 서비스 소개</a>
            </li>
            <li>
              <a target="_blank" :href="marketbomWholesalesInfoLink"
                @click="gtag('마켓봄 도매 소개페이지', 'gnb')">마켓봄 도매 서비스 소개</a>
            </li>
          </ul>
        </div>
        <div class="row type2">
          <div class="label">마켓봄 프로</div>
          <div class="img-box">
            <img src="@/assets/mobile/images/pic/img_gnb2.png" alt="">
          </div>
          <ul>
            <li>
              <a @click="clickTab('/')">마켓봄 프로 서비스 소개</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkMixin from '@/mixins/linkMixin.vue';
import GtagMixin from '@/mixins/gtagMixin.vue';

export default {
  mixins: [LinkMixin, GtagMixin],
  data: () => ({
    isVisibleMenu: false,
    path: '/',
    tabs: [
      { name: '서비스 소개', path: '/' },
      { name: '요금 안내', path: '/price' },
      { name: '상세 기능', path: '/guide' },
    ],
  }),
  methods: {
    toggleMenu(event) {
      if (event.target?.className === 'btn-menu') {
        this.isVisibleMenu = !this.isVisibleMenu;
      } else {
        this.isVisibleMenu = false;
      }
    },
    linkTo(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
        this.path = path;
      }
    },
    clickTab(tabPath) {
      this.linkTo(tabPath);
      if (tabPath === '/price') {
        this.gtag('요금안내', 'gnb');
      } else if (tabPath === '/guide') {
        this.gtag('상세기능 안내', 'gnb');
      } else if (tabPath === '/') {
        this.gtag('마켓봄 프로 메인', 'gnb');
      }
    },
    login() {
      this.goToLogin();
      this.gtag('로그인', 'gnb');
    },
  },
  mounted() {
    this.path = this.$route.path;
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/mobile/css/common/common.scss';
@import '@/assets/mobile/css/plugin/slick.scss';

.mkb-mobile {
  letter-spacing: -1px;
}

</style>
